import PropTypes from "prop-types";
import React from "react";
import Markdown from "../Markdown";
import Link from "../Link";
import { Box, Flex, Heading, Text, Button } from "rebass/styled-components";
import Fontello from "../Fontello";
import CustomImage from "../CustomImage";

const Card = ({ title, content, image, nbPers, price, highlighted }) => {
  const link = "#contact";
  const linklabel = "Profiter de l’offre";
  return (
    <Box variant="verticalCard" height="100%">
      <CustomImage
        height="auto"
        img={image}
        alt={title}
      />
      <Box variant="cardContent">
        {title && <Heading as="h3" variant="h3" color="primary">{title}</Heading>}
        {content && <Markdown>{content}</Markdown>}
        {nbPers && 
          <Flex alignItems="center" mb={6} pt={1}>
            <Box fontSize={9} color="primary" mr={2}><Fontello icon="user" /></Box>{nbPers} personnes
          </Flex>
        }
        <Flex variant="grid">
          <Box variant="gridItem" width="50%" color="primary" display="flex" flexDirection="column" justifyContent="flex-end">
            <Box fontSize={9} fontWeight="bold">{parseInt(price).toLocaleString()} Frs</Box>
            <Box fontSize={0} sx={{textTransform:"uppercase"}}>Installation incluse</Box>
          </Box>
          <Box variant="gridItem" width="50%">
            <Link href={link}>
              <Button variant="gradiant" width="100%">{linklabel}</Button>
            </Link>
          </Box>
        </Flex>
        {highlighted && 
          <Box
          bg="primary"
          py={1}
          px={10}
          sx={{
            textTransform: "uppercase",
            color: "white",
            fontSize: 2,
            textAlign: "center",
            transform: "rotate(-45deg) translateX(-50%)",
            transformOrigin: "0 0",
            position:"absolute",
            top: "30px",
            left: "30px"
          }}>Populaire</Box>
        }
      </Box>
    </Box>
  );
};

Card.propTypes = {
  content: PropTypes.any,
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        src: PropTypes.any
      })
    })
  }),
  link: PropTypes.string,
  linklabel: PropTypes.string,
  title: PropTypes.any
};

export default Card;
