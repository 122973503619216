import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import { Flex, Box, Heading, Text, Button } from "rebass/styled-components";
import Link from "../../UI/Link";
import Fontello from "../../UI/Fontello";
import { position } from "polished";
import CustomImage from "../../UI/CustomImage";

const Header = ({ data }) => {
  const fullHeightActived = data.blockSettings ? data.blockSettings.fullHeight : false;
  const bgImg = data.blockSettings
    ? data.blockSettings.bgImg
      ? data.blockSettings.bgImg
      : ""
    : "" || "";
  data.ctas = data.ctas || [];
  console.log(data);
  const bgColor = data.blockSettings
    ? data.blockSettings.bg
      ? data.blockSettings.bg
      : false
    : false || false;
  const sx = {
    backgroundImage: `url(${typeof bgImg === "string" ? bgImg : bgImg.childImageSharp.fluid.src})`,
    position: "relative",
    overflow: "hidden",
    ">*": {
      position: "relative",
      zIndex: 1
    },
    "&:after": {
      content: "''",
      bg: "white",
      width: "100%",
      height: "10vw",
      position: "absolute",
      left: 0,
      bottom: 0,
      transform: "translateY(100%) skew(0,-2deg)",
      transformOrigin: "left top"
    }
  };
  if (bgColor) {
    sx[":before"] = {
      content: '""',
      position: "absolute",
      zIndex: 0,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: "100%",
      height: "100%",
      backgroundColor: bgColor
    };
  }
  const containerProps = {};
  const wrapperProps = {};
  if (fullHeightActived) {
    if (data.blockSettings.padding) {
      containerProps.pb = data.blockSettings.padding.bottom;
      containerProps.pt = data.blockSettings.padding.top;
    }
    wrapperProps.pb = 0;
    wrapperProps.pt = 0;
  }
  return (
    <Wrapper
      data={data}
      sx={sx}
      containerProps={{
        display: "flex",
        alignItems: "center",
        justifyContent: ["center", "center"],
        ...containerProps
      }}
      display="flex"
      pt={["70px", "70px", "230px"]}
      pb={[0, 0, 0]}
      mb={[10, 10, 11]}
      {...wrapperProps}
    >
      <Flex flexDirection={["column", "column", "row"]}>
        <Flex
          textAlign={["center", "left", "left"]}
          pt={[13, 13, 11]}
          pb={[10, 10, 15]}
          alignItems={["center", "flex-start"]}
          flexDirection="column"
        >
          <Box>
            <Box maxWidth={["300px", "500px"]}>
              <Heading as="h1" variant="h1">
                {data.bigTitle}
              </Heading>
            </Box>
            <Box pb={4} maxWidth={["200px", "inherit"]}>
              <Text color="white">{data.subTitle}</Text>
            </Box>
            {data.ctas.map((cta, i) => (
              <Link href={cta.href} key={i}>
                <Button variant="white">
                  {cta.label}
                  <Fontello icon="chevron-down" />
                </Button>
              </Link>
            ))}
          </Box>
        </Flex>
        {/* block part 2 images */}
        <Flex
          flexDirection={["column-reverse", "column-reverse", "column"]}
          alignItems={["center", "center", "flex-end"]}
          pt={[0, 0, 11]}
          pb={[13, 13, 15, 20]}
        >
          <Flex justifyContent={["center"]}>
            <CustomImage
              img={data.textIncrevable}
              height="auto"
              width={["50vw", "50vw", "50vw", "600px"]}
            />
          </Flex>
          <Flex
            justifyContent={["center"]}
            flexDirection={["column-reverse", "column-reverse", "row"]}
            alignItems={["center"]}
          >
            <Box>
              <CustomImage img={data.packshot} width={["50vw", "50vw", "30vw", "320px"]} />
            </Box>
            <Box>
              <CustomImage img={data.text15ans} width={["50vw", "50vw", "30vw", "280px"]} />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Wrapper>
  );
};

Header.propTypes = {
  data: PropTypes.shape({
    bigTitle: PropTypes.any,
    textIncrevable: PropTypes.any,
    text15ans: PropTypes.any,
    packshot: PropTypes.any,
    blockSettings: PropTypes.shape({
      bg: PropTypes.any,
      bgImg: PropTypes.any
    }),
    ctas: PropTypes.array,
    subTitle: PropTypes.any
  })
};

export default Header;
export const query = graphql`
  fragment HeaderBlock on MarkdownRemarkFrontmatterBlockscollection {
    content
    bigTitle
    subTitle
    text15ans {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1000, quality: 64) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    textIncrevable {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1000, quality: 64) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    packshot {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1000, quality: 64) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ctas {
      href
      icon
      label
    }
  }
`;
