import PropTypes from "prop-types";
import React from "react";
import Wrapper from "../Wrapper";
import { graphql } from "gatsby";
import { Box, Flex, Heading } from "rebass/styled-components";
import Card from "../../UI/Card";
import Markdown from "../../UI/Markdown";
import CardSlider from "./CardSlider";
import Accordion from "../../UI/Accordion";

const Cards = ({ data }) => {
  data.cards = data.cards || [];
  return (
    <Wrapper data={data}>
      <Heading as="h2" variant="h2">{data.title}</Heading>
      <Box textAlign="center" maxWidth="970px" mx="auto">
        <Markdown>{data.content}</Markdown>
      </Box>
      <Flex variant="grid" justifyContent="center">
        {data.cards.map((card, i) => {
          return (
            <Box
              variant="gridItem"
              width={[1,1,1/2,1/2,1/3]}
              pt={6}
              key={"card-" + i}>
                <Card {...card} />
            </Box>
          );
        })}
      </Flex>
      <Box textAlign="center" maxWidth="970px" mx="auto" pt={11}>
        <Markdown>{data.terms1}</Markdown>
      </Box>
      <Box textAlign="center" maxWidth="970px" mx="auto" fontSize={2}>
        <Markdown>{data.terms2}</Markdown>
      </Box>
    </Wrapper>
  );
};

Cards.propTypes = {
  data: PropTypes.shape({
    blockSettings: PropTypes.shape({
      align: PropTypes.string
    }),
    cards: PropTypes.array
  })
};
export default Cards;

export const query = graphql`
  fragment CardsBlock on MarkdownRemarkFrontmatterBlockscollection {
    content
    title
    cards {
      content
      title
      image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 400, quality: 64) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      nbPers
      price
      highlighted
    }
    terms1
    terms2
  }
`;
