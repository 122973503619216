import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Grid from "../../UI/Grid";
import { Box, Heading } from "rebass/styled-components";
import CustomImage from "../../UI/CustomImage";
import Markdown from "../../UI/Markdown";

const Features = ({ data }) => {
  data.items = data.items || [];
  return (
    <Wrapper data={data}>
      <Heading as="h2" variant="h2">{data.title}</Heading>
      <Grid
      childWidth={[1, 1/2, 1/2, 1 / data.items.length]}
      bg="gray"
      pt={3}
      pb={8}
      sx={{
        position: "relative",
        "&:before": {
          content: "''",
          display: "block",
          width: "110vw",
          height: "100%",
          bg: "gray",
          position: "absolute",
          left: "50%",
          top: 0,
          transform: "translateX(-50%)",
          zIndex: 1,
        },
        "& > *": {
          position: "relative",
          zIndex: 2
        }
      }}
      >
        {data.items.map((item, i) => (
          <Box pt={6} key={"num-" + i} height="100%">
            <Box py={6}
            bg="white"
            textAlign="center" p={6} 
            height="100%"
            sx={{
              boxShadow: "card",
              borderRadius: "default"
            }}>
              <Box
                px={5}
                sx={{
                  ".gatsby-image-wrapper > div": {
                    paddingBottom: "100% !important" /* your aspect ratio */
                  }
                }}
              >
                <CustomImage img={item.image} />
              </Box>
              <Markdown>{item.content}</Markdown>
            </Box>
          </Box>
        ))}
      </Grid>
    </Wrapper>
  );
};

Features.propTypes = {
  data: PropTypes.shape({
    intro: PropTypes.any,
    items: PropTypes.array,
    title: PropTypes.any,
    content: PropTypes.any
  })
};
export default Features;

export const query = graphql`
  fragment FeaturesBlock on MarkdownRemarkFrontmatterBlockscollection {
    type
    title
    items {
      content
      image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 200, quality: 64) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
