import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Markdown from "../../UI/Markdown";
import Wrapper from "../Wrapper";
import { Box } from "rebass/styled-components";
import colors from "../../../theme/colors";

const Markup = ({ data }) => {
  return (
    <Wrapper data={data}>
      <Box 
      maxWidth="970px"
      mx="auto"
      p={10}
      textAlign="center"
      color="white"
      fontSize={9}
      sx={{
        background:colors.gradiantPrimary,        
        backgroundPosition: "100% 0%",
        backgroundSize: "200% 100%",
        borderRadius: "large",
        boxShadow: "blockqueote"
      }}>
        <Markdown>{data.content}</Markdown>
      </Box>
    </Wrapper>
  );
};

Markup.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.any,
    title: PropTypes.any
  })
};
export default Markup;

export const query = graphql`
  fragment MarkupBlock on MarkdownRemarkFrontmatterBlockscollection {
    content
  }
`;
