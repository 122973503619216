import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Grid from "../../UI/Grid";
import Markdown from "../../UI/Markdown";
import { Box, Heading, Flex, Text } from "rebass/styled-components";
import loadable from "@loadable/component";
import Forms from "../../Forms";
import Fontello from "../../UI/Fontello";

const Map = loadable(() => import("./Map"));

function isString(str) {
  if (str && typeof str.valueOf() === "string") {
    return true;
  }
  return false;
}

const FormMap = ({ data }) => {
  const hasData = data.form ? !isString(data.form) : false;
  const fluidContainer = data.blockSettings ? data.blockSettings.containerFluid : false;
  var telLink = "tel:+687";
  return (
    <Wrapper
      data={data}
      containerProps={{
        px: fluidContainer ? "0px !important" : ""
      }}
    >
      <Heading as="h2" variant="h2">{data.title}</Heading>
      <Flex alignItems="stretch">
        {hasData && (
          <Flex variant="grid" px={[2, 2, 0]}>
            <Flex
              variant="gridItem"
              alignItems="center"
              width={[1, 1, 1, 1 / 2]}
              px={fluidContainer ? [2, 2, 5] : ""}
              pt={6}
            >
              <Flex alignItems="center" pl={fluidContainer ? [0, 0, 5] : ""}>
                <Forms data={data.form.frontmatter} />
              </Flex>
            </Flex>
            <Box
              id="adresse"
              variant="gridItem"
              width={[1, 1, 1, 1 / 2]}
              pl={[0, 0, 5]}
              pt={6}
            >
              <Box height="100%">
                <Box height="100%">
                  {data.showInfo && (
                    <Box>
                      {data.cardTitle && (
                        <Heading as="h3" variant="h3" fontSize={7} color="primary" fontWeight="bold">{data.cardTitle}</Heading>
                      )}
                      {data.opentime && (
                        <Flex alignItems="flex-start" sx={{"p:first-child":{mt:0}}} flexWrap="wrap" mt={6}>
                          <Box fontSize={9} color="primary" mr={2}><Fontello icon="clock" /></Box>
                          <Box sx={{flex:1}} pt="4px">
                            <Markdown>{data.opentime}</Markdown>
                          </Box>
                        </Flex>
                      )}
                      {data.address && (
                        <Flex alignItems="flex-start" sx={{"p:first-child":{mt:0}}} flexWrap="wrap" mt={2}>
                          <Box fontSize={9} color="primary" mr={2}><Fontello icon="poi" /></Box>
                          <Box sx={{flex:1}} pt="4px">
                            <Markdown>{data.address}</Markdown>
                          </Box>
                        </Flex>
                      )}
                      {data.phone && (
                        <Flex alignItems="flex-start" sx={{"p:first-child":{mt:0}}} flexWrap="wrap" mt={2} mb={4}>
                          <Box fontSize={9} color="primary" mr={2}><Fontello icon="phone" /></Box>
                          <Box pt="4px"><a href={(telLink = telLink + data.phone.replace(/[- )(]/g, ""))}>{data.phone}</a></Box>                          
                        </Flex>
                      )}
                    </Box>
                  )}
                  <Box height="350px" sx={{ position: "relative" }}>
                    <Map position={data.position} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Flex>
        )}
        {!hasData && (
          <Box>
            <Text>Formulaire + carte Non disponible en previsualisation</Text>
          </Box>
        )}
      </Flex>
    </Wrapper>
  );
};

FormMap.propTypes = {
  data: PropTypes.shape({
    address: PropTypes.any,
    opentime: PropTypes.any,
    blockSettings: PropTypes.shape({
      containerFluid: PropTypes.any
    }),
    cardTitle: PropTypes.any,
    email: PropTypes.any,
    form: PropTypes.shape({
      frontmatter: PropTypes.any
    }),
    phone: PropTypes.any,
    position: PropTypes.any,
    showInfo: PropTypes.any
  })
};
export default FormMap;

export const query = graphql`
  fragment FormMapBlock on MarkdownRemarkFrontmatterBlockscollection {
    form {
      frontmatter {
        title
        formId
        successMessage
        elements {
          imputType
          label
          name
          printLabel
          placeholder
          required
          type
          default
          multiple
          options {
            value
            label
          }
        }
      }
    }
    position
    address
    opentime
    cardTitle
    phone
    email
    showInfo
    title
  }
`;
